import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from '../../components/PreChorus';
import Verse from "../../components/Verse.js";

export default function Levitating() {
  return (
    <Layout>
      <Helmet title="Levitating | Dua Lipa" />

      <h1>Levitating</h1>

      <h2>Dua Lipa</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>If you wanna run away with me, I know a galaxy</p>
          <p>And I can take you for a ride</p>
          <p>I had a premonition that we fell into a rhythm</p>
          <p>Where the music don't stop for life</p>
          <p>Glitter in the sky, glitter in my eyes</p>
          <p>Shining just the way I like</p>
          <p>If you're feeling like you need a little bit of company</p>
          <p>You met me at the perfect time</p>
        </Verse>
        <PreChorus>
          <p>You want me, I want you, baby</p>
          <p>My sugarboo, I'm levitating</p>
          <p>The Milky Way, we're renegading</p>
          <p>Yeah, yeah, yeah, yeah, yeah</p>
        </PreChorus>
        <Chorus>
          <p>I got you, moonlight, you're my starlight</p>
          <p>I need you all night, come on, dance with me</p>
          <p>I'm levitating</p>
          <p>You, moonlight, you're my starlight (You're the moonlight)</p>
          <p>I need you all night, come on, dance with me</p>
          <p>I'm levitating</p>
        </Chorus>
        <Verse>
          <p>I believe that you're for me, I feel it in our energy</p>
          <p>I see us written in the stars</p>
          <p>We can go wherever, so let's do it now or never</p>
          <p>Baby, nothing's ever, ever too far</p>
          <p>Glitter in the sky, glitter in our eyes</p>
          <p>Shining just the way we are</p>
          <p>I feel like we're forever every time we get together</p>
          <p>But whatever, let's get lost on Mars</p>
        </Verse>
        <PreChorus>
          <p>You want me, I want you, baby</p>
          <p>My sugarboo, I'm levitating</p>
          <p>The Milky Way, we're renegading</p>
          <p>Yeah, yeah, yeah, yeah, yeah</p>
        </PreChorus>
        <Chorus>
          <p>I got you, moonlight, you're my starlight</p>
          <p>I need you all night, come on, dance with me</p>
          <p>I'm levitating</p>
          <p>You, moonlight, you're my starlight (You're the moonlight)</p>
          <p>I need you all night, come on, dance with me</p>
          <p>I'm levitating</p>
        </Chorus>
        <Bridge>
          <p>You can fly away with me tonight</p>
          <p>You can fly away with me tonight</p>
          <p>Baby, let me take you for a ride</p>
          <p>Yeah, yeah, yeah, yeah, yeah</p>
          <p>I'm levitating (Woo)</p>
          <p>You can fly away with me tonight</p>
          <p>You can fly away with me tonight</p>
          <p>Baby, let me take you for a ride</p>
          <p>Yeah, yeah, yeah, yeah, yeah (Woo)</p>
        </Bridge>
        <Bridge>
          <p>My love is like a rocket, watch it blast off</p>
          <p>And I'm feeling so electric, dance my ass off</p>
          <p>And even if I wanted to, I can't stop</p>
          <p>Yeah, yeah, yeah, yeah, yeah</p>
          <p>My love is like a rocket, watch it blast off</p>
          <p>And I'm feeling so electric, dance my ass off</p>
          <p>And even if I wanted to, I can't stop</p>
          <p>Yeah, yeah, yeah, yeah, yeah</p>
        </Bridge>
        <PreChorus>
          <p>You want me, I want you, baby</p>
          <p>My sugarboo, I'm levitating</p>
          <p>The Milky Way, we're renegading</p>
          <p>Yeah, yeah, yeah, yeah, yeah</p>
        </PreChorus>
        <Chorus>
          <p>I got you, moonlight, you're my starlight</p>
          <p>I need you all night, come on, dance with me</p>
          <p>I'm levitating</p>
        </Chorus>
        <Bridge>
          <p>You can fly away with me tonight</p>
          <p>You can fly away with me tonight</p>
          <p>Baby, let me take you for a ride</p>
          <p>Yeah, yeah, yeah, yeah, yeah</p>
          <p>I'm levitating (Woo)</p>
          <p>You can fly away with me tonight</p>
          <p>You can fly away with me tonight</p>
          <p>Baby, let me take you for a ride</p>
          <p>Yeah, yeah, yeah, yeah, yeah (Woo)</p>
        </Bridge>
        <Chorus>
          <p>I got you, moonlight, you're my starlight</p>
          <p>I need you all night, come on, dance with me</p>
          <p>I'm levitating</p>
          <p>I got you, moonlight, you're my starlight</p>
          <p>I need you all night, come on, dance with me</p>
          <p>I'm levitating</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
